import BoostVideoTableRow from "./boostVideoTableRow";

function BoostVideoTable() {
  return (
    <div className="w-full flex-1 flex flex-col min-h-0">
      <div className="flex pl-[23px] w-full gap-x-[20px] text-sm font-medium text-[#777E8F] leading-none border-b border-solid border-[#20232C]">
        <div className="py-4 w-[86px] shrink-0">Thumbnail</div>
        <div className="py-4 flex-[3] mr-3">Title</div>
        <div className="py-4 flex-1">VPH</div>
        <div className="py-4 flex-1">Peak VPH</div>
        <div className="py-4 flex-1">Likes</div>
        <div className="py-4 flex-1">Comments</div>
        <div className="py-4 flex-[1.3]">VPH/time</div>
        <div className="py-4 flex-[1.3]">Status</div>
        <div className="py-4 pr-1 flex-[1.3] mr-1">Action</div>
      </div>
      <div className="flex-1 overflow-auto black-scrollbar">
        <BoostVideoTableRow />
        <BoostVideoTableRow />
        <BoostVideoTableRow />
        <BoostVideoTableRow />
        <BoostVideoTableRow />
      </div>
    </div>
  );
}

export default BoostVideoTable;
