import { combineReducers } from "@reduxjs/toolkit";

import userDetailsReducer from "actionsAndReducers/userDetails/reducer";
import projectsReducer from "actionsAndReducers/projects/reducer";
import videosReducer from "actionsAndReducers/videos/reducer";
import channelsReducer from "actionsAndReducers/channels/reducer";

export const reducer = combineReducers({
  userDetails: userDetailsReducer,
  projects: projectsReducer,
  videos: videosReducer,
  channels: channelsReducer,
});
