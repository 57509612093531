import { useEffect } from "react";


import {
  collection,
  query,
  where,
  doc,
  updateDoc,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { firebaseDb } from "utilities/firebase";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  videosFetchLoadingStart,
  videosFetchLoadingFail,
  videosFetchLoadingSuccess,
  videosReset,
} from "actionsAndReducers/videos/action";

import { IVideoList, ISingleVideoItem } from "interfaces/videos.interface";
import { ISelectOptionItems } from "interfaces/sortCategory.interface";

export function useLoadVideoData(
  selectedprojectId?: string,
  isSavedVideosOnly?: boolean,
  selectedSortCategory?: ISelectOptionItems,
  isSortChecked?: boolean
) {
  const email = useAppSelector((state) => state.userDetails.email);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (email && selectedprojectId) {
      try {
        dispatch(videosFetchLoadingStart());

        const documentRef = collection(firebaseDb, "videos");

        let q = query(
          documentRef,
          orderBy("data.views", "desc"),
          limit(1000),
          where("data.projectId", "==", selectedprojectId)
        );

        if (selectedSortCategory) {
          q = query(
            documentRef,
            orderBy(`data.${selectedSortCategory.value}`, "desc"),
            limit(1000),
            where("data.projectId", "==", selectedprojectId)
          );
        }

        if (isSavedVideosOnly) {
          q = query(q, where("data.isSaved", "==", isSavedVideosOnly));
        }
        if (isSortChecked) {
          q = query(q, where("data.isShort", "==", isSortChecked));
        }
        getDocs(q)
          .then((querySnapshot) => {
            const videos: IVideoList = [];

            querySnapshot.forEach((doc) => {
              const id = doc.id;

              if (id) {
                videos.push({
                  id,
                  ...(doc?.data()?.data as Omit<ISingleVideoItem, "id">),
                });
              }
            });

            dispatch(videosFetchLoadingSuccess(videos));
          })
          .catch(() => {
            dispatch(videosFetchLoadingFail("Something went wrong."));
          });
      } catch (e) {
        dispatch(videosFetchLoadingFail("Something went wrong."));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, selectedprojectId, selectedSortCategory, isSortChecked]);

  useEffect(() => {
    return () => {
      dispatch(videosReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function updateVideoSavedStatusFirebase(
  videoId: string,
  isSaved: boolean
) {
  const docRef = doc(firebaseDb, "videos", videoId);

  return updateDoc(docRef, { "data.isSaved": isSaved });
}
