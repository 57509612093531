import { useState } from "react";

import { Popover } from "@mui/material";
import { ISelectOptionItems } from "interfaces/sortCategory.interface";

function DropDown({
  className = "",
  label,
  selectedValueName,
  dropdownCategories,
  setDropdownCategories
}: {
  className?: string;
  label: string;
  selectedValueName: string;
  dropdownCategories: ISelectOptionItems[];
  setDropdownCategories:React.Dispatch<React.SetStateAction<ISelectOptionItems>>
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDropDownClick = (index:number) => {
    setDropdownCategories(dropdownCategories[index])
    handleClose()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "dropDown-popover" : undefined;

  return (
    <>
      <button
        type="button"
        className={`flex cursor-pointer text-sm leading-none items-center border border-solid border-[#272C37] bg-[#20232C] rounded-[6px] py-[10px] px-2 ${className}`}
        aria-describedby={id}
        onClick={handleClick}
      >
        <span className="font-medium text-[#777E8F] mr-1">{label}</span>
        <span className="font-semibold text-[#DDDDDD] mr-1">
          {selectedValueName}
        </span>
        <div
          className={`shrink-0 ml-[6px] border-solid border-[#DDDDDD] border-b-[4px] border-x-transparent border-x-[4px] border-t-0 ${
            open ? "" : "rotate-[180deg]"
          }`}
        ></div>
      </button>
      <Popover
        classes={{ paper: "bg-transparent" }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-2"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <ul className="w-[170px] rounded-[6px] bg-[#20232C] text-sm font-normal text-[#DDDDDD] border border-solid border-[#272C37]">
          {dropdownCategories?.map((item: ISelectOptionItems,index:number) => (
            <li
              className="hover:bg-white/5 cursor-pointer px-3 py-2 border-b border-solid border-[#272C37]"
              onClick={() => handleDropDownClick(index)}
              key={item.value}
            >
              {item?.label}
            </li>
          ))}
        </ul>
      </Popover>
    </>
  );
}

export default DropDown;
