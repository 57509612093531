import { useState } from "react";

import { ReactComponent as BoostIcon } from "assetsImport/images/navIcons/boost.svg";

import Button from "@mui/material/Button";

import FacebookCircularProgress from "components/common/facebookCircularProgress";
import AreaChart from "../areaChart";

function BoostVideoTableRow() {
  const [isBoostEnabled, setIsBoostEnabled] = useState(false);
  
  return (
    <div className="flex gap-x-[20px] pl-[23px] items-center text-sm font-normal text-[#AFB4B9]">
      <div className="py-2 w-[86px] shrink-0">
        <div className="h-[48px] w-full bg-white" />
      </div>
      <div className="py-2 flex-[3] mr-3">
        Bajaj Pulsar P150 Ride Review - Is it FASTER vs the N160 & Apache 160?
        Ft. On Road Price, Mileage
      </div>
      <div className="py-2 flex-1">177</div>
      <div className="py-2 flex-1">254</div>

      <div className="py-2 flex-1">1.7k</div>
      <div className="py-2 flex-1">347</div>
      <div className="py-2 flex-[1.3]">
        <div className="h-[32px] max-w-[80px]">
          <AreaChart areaData={[]} />
        </div>
      </div>
      <div className="py-2 flex-[1.3]">
        <div
          className="text-sm font-semibold text-[#DDD] leading-none inline-flex items-center gap-[5px] py-1.5 px-2 min-w-[95px] bg-[#20232C] rounded"
          style={{
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.12)",
          }}
        >
          {isBoostEnabled ? (
            <>
              Boosting <FacebookCircularProgress size={13} />
            </>
          ) : (
            "Not Boosted"
          )}
        </div>
      </div>
      <div className="py-2 pr-1 flex-[1.3]">
        <Button
          variant="contained"
          className={`p-2 min-[81px] rounded flex items-center gap-[7px] text-base leading-none font-semibold tracking-[-0.02em] 
          ${
            isBoostEnabled
              ? "bg-[#20232C] text-[#DDDDDD]"
              : "bg-[#3254CE] text-white"
          } `}
          onClick={() => setIsBoostEnabled(!isBoostEnabled)}
        >
          {isBoostEnabled ? (
            <>
              Stop{" "}
              <span className="inline-block w-[10px] h-[10px] bg-white rounded-sm" />
            </>
          ) : (
            <>
              Boost
              <BoostIcon className="w-[17px] shrink-0" />
            </>
          )}
        </Button>
      </div>
    </div>
  );
}

export default BoostVideoTableRow;
