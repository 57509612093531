import Header from "components/common/header";
import ProjectsSiebar from "components/common/projectsSiebar";

function Layout1({
  className = "",
  children,
}: {
  className?: string;
  children: JSX.Element;
}) {
  return (
    <div className={`h-[100vh] flex flex-col ${className}`}>
      <Header className="w-full" />
      <div className="flex items-stretch flex-1 min-h-[0]">
        <ProjectsSiebar />
        <div className="bg-[#0a0c10]  flex-1 min-w-0">{children}</div>
      </div>
    </div>
  );
}

export default Layout1;
