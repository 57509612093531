import Box from "@mui/material/Box";

import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";

function FacebookCircularProgress({
  size = 13,
  circleProps = {},
}: {
  size?: number;
  circleProps?: CircularProgressProps;
}) {
  return (
    <Box sx={{ position: "relative", height: `${size}px`, width: `${size}px` }}>
      <CircularProgress
        variant="determinate"
        className="text-white/50"
        size={size}
        thickness={4}
        value={100}
        {...circleProps}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className="text-white"
        sx={{
          animationDuration: "600ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={size}
        thickness={4}
        {...circleProps}
      />
    </Box>
  );
}

export default FacebookCircularProgress;
