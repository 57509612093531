import { useMemo, useEffect } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

import { useAppSelector } from "hooks/redux";

import CustomImage from "components/common/customImage";
import AddProjectButton from "./addProjectButton";



function ProjectsSiebar({ className = "" }: { className?: string }) {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const navigate = useNavigate();

  

  const email = useAppSelector((state) => state.userDetails.email);

  const projects = useAppSelector((state) => state.projects.data);

  const projectList = useMemo(() => {
    const output = [];

    for (const key in projects) {
      output.push({ ...projects[key], id: key });
    }

    return output;
  }, [projects]);

  useEffect(() => {
    if (!projectId && projectList.length > 0) {
      navigate(`${pathname}/${projectList[0].id}`);
    }
  }, [navigate, pathname, projectId, projectList]);

  return (
    <div
      className={`w-[248px] bg-[#030507] flex flex-col border-r border-solid border-[#20232C] overflow-auto black-scrollbar ${className}`}
    >
      <div className="border border-b border-[#DFDFDF] mt-[43px] flex-1 flex flex-col min-h-[200px]">
        <div className="flex justify-between items-center px-6">
          <span className="text-xs font-semibold text-[#AFB4B9] leading-none">
            PROJECTS
          </span>
          <AddProjectButton />
        </div>
        {projectList.length === 0 && (
          <p className="text-sm font-medium text-[#DDD] text-center mt-5">
            No project found
          </p>
        )}
        {projectList?.length > 0 && (
          <ul className="mt-[27px] px-[14px] flex flex-col overflow-auto black-scrollbar flex-1 min-h-[100px] gap-3">
            {projectList.map(({ name, id, image }) => (
              <li key={id}>
                <NavLink
                  className="w-full"
                  to={
                    projectId
                      ? pathname?.replace(`/${projectId}`, `/${id}`)
                      : `${pathname}/${id}`
                  }
                >
                  {({ isActive }) => (
                    <div
                      className={`flex items-center justify-between py-[5px] pl-[10px] pr-3 rounded hover:bg-[#20232C] ${
                        isActive ? "bg-[#20232C]" : ""
                      }`}
                    >
                      <div className="flex items-center gap-3 min-w-0">
                        <CustomImage
                          src={image}
                          alt="image"
                          className="w-[26px] h-[26px] inline-block rounded-full shrink-0"
                        />
                        <span
                          className={`text-sm font-medium truncate ${
                            isActive ? "text-[#DDD]" : "text-[#9CA1AD]"
                          }`}
                        >
                          {name}
                        </span>
                      </div>

                      <MdOutlineArrowForwardIos
                        className={`shrink-0 text-[#525865] ${
                          isActive ? "" : "opacity-0"
                        }`}
                      />
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-auto p-4 flex items-center border-t border-solid border-[#20232C]">
        <div className="mr-3 text-[18px] font-normal text-[#B5B5B5] leading-none bg-[#503636] rounded w-[40px] h-[40px] flex items-center justify-center">
          SM
        </div>
        <div className="min-w-0">
          <div className="text-sm font-medium text-[#AFB4B9] leading-none">
            Semyon Martynov
          </div>
          <div className="mt-2 pb-[1px] text-[#AFB4B9] text-sm font-normal leading-none truncate">
            {email}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsSiebar;
