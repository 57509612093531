import { useEffect } from "react";
import { toast } from "react-toastify";

import { collection, onSnapshot, addDoc } from "firebase/firestore";
import { firebaseDb } from "utilities/firebase";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  projectsFetchLoadingStart,
  projectsFetchLoadingFail,
  projectsFetchLoadingSuccess,
  projectsReset,
} from "actionsAndReducers/projects/action";

import { IProjects, ISingleProject } from "interfaces/projects.interface";

export function useProjectData() {
  const email = useAppSelector((state: any) => state.userDetails.email);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let unsubscribe: any;

    if (email) {
      try {
        dispatch(projectsFetchLoadingStart());

        const documentRef = collection(firebaseDb, "projects");

        unsubscribe = onSnapshot(documentRef, (querySnapshot) => {
          const projects: IProjects = {};
          querySnapshot.forEach((doc) => {
            const id = doc.id;

            if (id) {
              projects[id] = doc?.data()?.data as ISingleProject;
            }
          });

          dispatch(projectsFetchLoadingSuccess(projects));
        });
      } catch (e) {
        dispatch(projectsFetchLoadingFail("Something went wrong."));
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    return () => {
      dispatch(projectsReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function addProject(
  data: {
    admin: string;
    channels: [];
    createdAt: Date;
    image: string;
    lastUpdatedAt: Date;
    members: string[];
    name: string;
  },
  callbacks: {
    onStart: () => void;
    onSuccess: () => void;
  }
) {
  const docRef = collection(firebaseDb, "projects");

  const { onSuccess, onStart } = callbacks || {};

  if (onStart) {
    onStart();
  }

  addDoc(docRef, { data })
    .then((docRef) => {
      if (onSuccess) {
        onSuccess();
      }
      // console.log(
      //   "Document has been deleted"
      // );
    })
    .catch((error) => {
      toast.error("Something went wrong!");
    });
}
