function SearchBar({
  className = "",
  placeholder = "Search videos",
  value,
  onChange,
}: {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className={`inline-block relative h-[36px] ${className}`}>
      <img
        src="/images/trending-videos-search-icon.svg"
        alt="icon"
        className="w-[20px] absolute top-1/2 -translate-y-1/2 left-[12px]"
      />
      <input
        type="text"
        className="w-[270px] p-2 pl-[42px] h-full bg-[#20232C] border border-solid border-[#272C37] outline-0 text-sm font-medium text-[#9CA1AD] placeholder:text-[#9CA1AD]/30 rounded-[6px]"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;
