import { useState } from "react";
import { Button, Popover } from "@mui/material";
import { BsPlusLg } from "react-icons/bs";

import AddChannelCard from "./addChannelCard";

function AddChannelButton({ className = "" }: { className?: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "addChannelButton-popover" : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant="contained"
        className={`rounded bg-[#3254CE] hover:bg-[#3254CE]/80 capitalize px-3 py-[10px] text-base font-semibold leading-none text-white ${className}`}
        style={{
          boxShadow: "0px 1px 4px 0px #0000001F",
        }}
      >
        Add Channel
        <BsPlusLg size={12} className="ml-2" />
      </Button>
      <Popover
        classes={{ paper: "bg-transparent" }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-2"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AddChannelCard handleClose={handleClose} />
      </Popover>
    </>
  );
}

export default AddChannelButton;
