import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainRoutes from "./MainRoutes";

function App() {
  return (
    <div>
      <MainRoutes />
      <ToastContainer theme="colored" position={toast.POSITION.TOP_RIGHT} />
    </div>
  );
}

export default App;
