import { memo, useCallback } from "react";
import { Tooltip } from "@mui/material";

interface Props {
  src: string;
  alt: string;
  className?: string;
  tooltipText?: string;
}
const CustomImage: React.FC<Props> = ({ src, alt, className, tooltipText }) => {
  const handleImageError = useCallback((e: any) => {
    // @ts-ignore
    e.target.src = "/images/imagePlaceholder.svg";
    // @ts-ignore
    e.target.onerror = null;
  }, []);

  return (
    <Tooltip title={tooltipText} arrow placement="top">
      <img
        className={className}
        src={src || "/images/imagePlaceholder.svg"}
        alt=""
        loading="lazy"
        onError={handleImageError}
      />
    </Tooltip>
  );
};

export default memo(CustomImage);
