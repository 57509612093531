import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

import { navListData } from "./data";

function Header({ className = "" }: { className?: string }) {
  const { projectId } = useParams();

  return (
    <header
      className={`flex items-center bg-[#14181D] flex-wrap border-b border-solid border-[#20232C] ${className}`}
    >
      <div className="min-h-[64px] pl-[28px] flex items-center shrink-0 py-3 w-[248px] border-r border-solid border-[#20232C] bg-[#030507]">
        <img src="/images/logo.svg" alt="icon" className="w-[30px] h-[30px]" />
        <span className="ml-[11px] text-[20px] font-bold text-[#DDDDDD]">
          SMM Toolkit
        </span>
      </div>
      {navListData?.length > 0 && (
        <nav className="py-2 px-[28px] mx-auto">
          <ul className="flex gap-6 flex-wrap items-center text-base font-semibold">
            {navListData.map(
              ({ title, icon: Icon, path, isCurrentParamAdd }) => (
                <li className="flex items-center gap-1" key={title}>
                  <NavLink
                    to={`${path}${isCurrentParamAdd ? `/${projectId}` : ""}`}
                  >
                    {({ isActive }) => (
                      <span
                        style={{
                          boxShadow: isActive
                            ? "0px 1px 4px rgba(0, 0, 0, 0.12)"
                            : "",
                        }}
                        className={`flex items-center leading-none gap-1 px-2 py-[6px] rounded-[7px] hover:text-[#DDDDDD] hover:bg-[#3254CE] ${
                          isActive
                            ? "text-[#DDDDDD] bg-[#3254CE]"
                            : "text-[#777E8F]"
                        }`}
                      >
                        {Icon && <Icon />}
                        {title}
                      </span>
                    )}
                  </NavLink>
                </li>
              )
            )}
          </ul>
        </nav>
      )}
    </header>
  );
}

export default Header;
