import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD7qlOD6Qa-BzCCeehFsknZ3iEN1448ihw",
  authDomain: "yt-tracker-spy.firebaseapp.com",
  projectId: "yt-tracker-spy",
  storageBucket: "yt-tracker-spy.appspot.com",
  messagingSenderId: "81815235187",
  appId: "1:81815235187:web:619dd18c68dae5eb3e9806",
  measurementId: "G-X6BP8NES27",
};

const app = initializeApp(firebaseConfig);

export const firebaseDb = getFirestore(app);
