import { useParams } from "react-router-dom";

import Layout1 from "layouts/layout1";
import CompetitorsPageContent from "components/competitorsPage/competitorsPageContent";

import { useLoadChannelData } from "hooks/data/useLoadChannelData";

import { withAuth } from "hoc/withAuth";

function CompetitorsPage() {
  const { projectId } = useParams();

  useLoadChannelData(projectId);

  return (
    <Layout1>
      <CompetitorsPageContent />
    </Layout1>
  );
}

export default withAuth(CompetitorsPage);
