import { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, CircularProgress } from "@mui/material";

import { addChannel } from "hooks/data/useLoadChannelData";

function AddChannelCard({ handleClose }: { handleClose: () => void }) {
  const [channelUrl, setChannelUrl] = useState("");
  const [isChannelAdditionInProgress, setIsChannelAdditionInProgress] =
    useState(false);

  const { projectId } = useParams();

  return (
    <div
      className="bg-[#14181F] w-full max-w-[393px] border border-solid border-[#20232C] rounded"
      style={{
        boxShadow: "0px 4px 4px 0px #00000014",
      }}
    >
      <h5 className="py-5 px-6 border-b rounded-t-[inherit] border-solid border-[#0A0C10] text-[18px] font-bold leading-none text-[#DDD]">
        Add a channel as a competitor
      </h5>
      <div className="p-6 border-t border-solid border-[#20232C]">
        <h6 className="text-[#DDDDDD] text-base font-semibold leading-none">
          Paste Channel URL
        </h6>
        <p className="mt-[7px] text-sm font-normal text-[#AFB4B9] leading-none">
          eg. https://www.youtube.com/@MrBeast
        </p>
        <div className="mt-4">
          <input
            type="text"
            className="bg-[#20232D] outline-0 rounded-[6px] border border-solid border-[#20232C] py-2 px-[10px] text-[#AFB4B9] text-sm font-medium w-full placeholder:text-[#AFB4B9]/50"
            placeholder="Enter Channel URL"
            value={channelUrl}
            onChange={(e) => setChannelUrl(e.target.value)}
          />
        </div>
        {channelUrl && (
          <div className="mt-6">
            <h6 className="text-base font-semibold leading-none text-[#DDDDDD]">
              Preview
            </h6>
            <div className="mt-3 rounded bg-[#20232D] p-4 flex items-center gap-3">
              <div className="w-[40px] h-[40px] bg-[#DDD] shrink-0 rounded-full" />
              <div className="flex-1 min-w-0">
                <div className="flex gap-[9px] items-center">
                  <span className="text-[#DDDDDD] font-bold text-[18px] leading-none truncate">
                    MrBeast
                  </span>
                  <span className="w-[14px] h-[14px] shrink-0 bg-[#3254CE] rounded-full inline-flex items-center justify-center">
                    <BsCheckLg size={6} className="text-white" />
                  </span>
                </div>
                <p className="mt-2 text-sm font-medium leading-none text-[#AFB4B9]">
                  124M subscribers
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center justify-end gap-2 bg-[#20232D] border-t-[2px] border-solid border-[#0A0C10] rounded-b-[inherit] py-4 px-6">
        <Button
          onClick={handleClose}
          variant="contained"
          className="rounded bg-[#333848] hover:bg-[#333848]/80 capitalize px-4 py-[9px] text-sm font-semibold leading-none text-white"
          style={{
            boxShadow: "0px 1px 4px 0px #0000001F",
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isChannelAdditionInProgress}
          variant="contained"
          className={`rounded bg-[#3254CE] hover:bg-[#3254CE]/80 capitalize px-[14px] py-[9px] text-sm font-semibold leading-none text-white ${
            isChannelAdditionInProgress ? "grayscale" : ""
          }`}
          style={{
            boxShadow: "0px 1px 4px 0px #0000001F",
          }}
          onClick={() => {
            if (projectId) {
              addChannel(
                {
                  channelURL: channelUrl,
                  status: "pending",
                  createdAt: new Date(),
                  projectId,
                },
                {
                  onStart: () => {
                    setIsChannelAdditionInProgress(true);
                  },
                  onSuccess: () => {
                    handleClose();
                    setIsChannelAdditionInProgress(false);
                  },
                }
              );
            } else {
              toast.error("No Project found!");
            }
          }}
        >
          Done
          {isChannelAdditionInProgress && (
            <CircularProgress className="text-white ml-2" size={14} />
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddChannelCard;
