import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { toast } from "react-toastify";

import { userDetailsSlice } from "./reducer";

export const { login, signUp, logout } = userDetailsSlice.actions;

const auth = getAuth();

interface ILoginFormInfo {
  email: string;
  password: string;
}

export const signUpThunk = createAsyncThunk(
  "userDetails/signUpThunk",
  ({ email, password }: ILoginFormInfo, { dispatch }) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        dispatch(signUp({ email, uid: userCredential?.user.uid }));
      })
      .catch((error) => {
        const errorMessage = error.message;

        toast.error(errorMessage || "login Failed!");
      });
  }
);

export const loginThunk = createAsyncThunk(
  "userDetails/loginThunk",
  ({ email, password }: ILoginFormInfo, { dispatch }) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        dispatch(login({ email, uid: userCredential?.user.uid }));
      })
      .catch((error) => {
        const errorMessage = error.message;

        toast.error(errorMessage || "login Failed!");
      });
  }
);
