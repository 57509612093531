import { useState } from "react";
import { Popover } from "@mui/material";
import { AiOutlinePlus } from "react-icons/ai";

import AddProjectPopupCard from "./addProjectPopupCard";

function AddProjectButton({ className = "" }: { className?: string }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "addChannelButton-popover" : undefined;

  return (
    <>
      <button type="button" aria-describedby={id} onClick={handleClick}>
        <AiOutlinePlus
          size={16}
          className="text-[#808080] hover:text-white/70"
        />
      </button>

      <Popover
        classes={{ paper: "bg-transparent" }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="mt-2"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <AddProjectPopupCard handleClose={handleClose} />
      </Popover>
    </>
  );
}

export default AddProjectButton;
