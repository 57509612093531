import { BsCheckLg } from "react-icons/bs";
import moment from "moment";

import CustomImage from "components/common/customImage";

import { ISingleVideoItem } from "interfaces/videos.interface";

import { numberFormater, getEmptyValue } from "utilities/functions";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import AreaChart from "components/boostPage/areaChart";

import { updateSavedVideosThunk } from "actionsAndReducers/videos/action";

function VideoDetailSingleItem({ data }: { data: ISingleVideoItem }) {
  const channelsData = useAppSelector((state) => state.channels.data);

  const dispatch = useAppDispatch();

  const {
    id,
    title,
    videoId,
    views,
    likes,
    isSaved,
    comments,
    uploadDate,
    channelId,
    averageVPH,
  } = data || {};

  const { image: channelImage, name: channelName } =
    (channelsData && channelsData[channelId]) || {};

  return (
    <div
      className={`flex gap-x-[20px] items-center text-sm font-normal text-[#AFB4B9] bg-[#14181D] border-y border-solid border-t-[#20232C] border-b-[#0A0C10] first:border-t-0 `}
    >
      <a
        href={`https://www.youtube.com/watch?v=${videoId}`}
        target="_blank"
        rel="noopener noreferrer"
        className="pl-4  w-[146px]  shrink-0"
      >
        <CustomImage
          src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
          alt="thumbnail"
          className="h-[67px] w-full object-cover object-center"
        />
      </a>
      <div className="py-2 flex-[1.3] text-center">
        <a
          href={`https://www.youtube.com/channel/${channelId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomImage
            tooltipText={channelName}
            src={channelImage}
            alt="thumbnail"
            className="h-[28px] w-[28px] rounded-full object-cover object-center"
          />
        </a>
      </div>
      <div className="py-2 flex-[3]">
        <a
          href={`https://www.youtube.com/watch?v=${videoId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="custome__line_clamp_3 max-h-[60px] text-inherit"
        >
          {getEmptyValue(title)}
        </a>
      </div>
      <div className="py-2 flex-1">{numberFormater(getEmptyValue(views))}</div>
      <div className="py-2 flex-1">{numberFormater(getEmptyValue(likes))}</div>

      <div className="py-2 flex-1">
        {numberFormater(getEmptyValue(comments))}
      </div>
      <div
        className="h-[32px] 
      flex-[1] "
      >
        <AreaChart areaData={data?.weeklyVPH} />
      </div>
      <div
        className="h-[32px] 
      flex-[1] "
      >
        <AreaChart areaData={data?.dailyVPH} />
      </div>
      <div className="py-2 flex-1">
        <span className="text-sm leading-none font-bold text-[#01B07C] inline-block bg-[#152726] rounded-sm px-2 py-1">
          {numberFormater(getEmptyValue(averageVPH),0)}
        </span>
      </div>
      <div className="py-2 flex-[1.5]">
        {getEmptyValue(moment(uploadDate.toDate()).fromNow())}
      </div>
      <div className="py-2 pr-1 flex-1 text-center">
        <div
          className="w-[24px] h-[24px] relative mx-auto cursor-pointer"
          onClick={() =>
            dispatch(updateSavedVideosThunk({ videoId: id, isSaved: !isSaved }))
          }
        >
          <img
            src={
              isSaved
                ? "/images/saveIcon/selected-save-icon.svg"
                : "/images/saveIcon/unselected-save-icon.svg"
            }
            alt="icon"
            className="w-full h-full absolute left-0 top-0"
          />
          {isSaved && (
            <div className="w-3 h-3 shrink-0 absolute right-0 top-0 bg-[#000] rounded-full inline-flex items-center justify-center">
              <BsCheckLg size={6} className="text-white" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoDetailSingleItem;
