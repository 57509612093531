import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IChannels } from "interfaces/channels.interface";

export interface IChannelsState {
  data: IChannels;
  isLoading: boolean;
  error: string;
}

const initialState: IChannelsState = {
  data: {},
  isLoading: false,
  error: "",
};

export const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    channelsFetchLoadingStart: (state) => {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    },
    channelsFetchLoadingSuccess: (
      state,
      { payload }: PayloadAction<IChannels>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: "",
        data: payload,
      };
    },
    channelsFetchLoadingFail: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    },
    channelsReset: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export default channelsSlice.reducer;
