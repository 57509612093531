import { useMemo, useCallback } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { Flipper, Flipped } from "react-flip-toolkit";
import { FixedSizeList as List } from "react-window";

import CompetitorsChannelTableRow from "./competitorsChannelTableRow";

import { useAppSelector } from "hooks/redux";

function CompetitorsChannelTable({ searchText }: { searchText: string }) {
  const channelsData = useAppSelector((state) => state.channels.data);

  const channelList = useMemo(() => {
    const output = [];

    for (const key in channelsData) {
      const { name } = channelsData[key];

      const nameKeyword = (name || "")?.trim().toLowerCase();
      const searchTextKeyword = (searchText || "")?.trim().toLowerCase();

      if (!searchTextKeyword || nameKeyword?.includes(searchTextKeyword)) {
        output.push({ ...channelsData[key], id: key });
      }
    }

    return output;
  }, [channelsData, searchText]);

  const _rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const listingItemData = channelList[index];

      const key = listingItemData.id;

      return (
        <div key={key} style={style}>
          <Flipped key={key} flipId={key}>
            <div>
              <CompetitorsChannelTableRow data={listingItemData} />
            </div>
          </Flipped>
        </div>
      );
    },
    [channelList]
  );

  const autoSizerMemo = useMemo(() => {
    return (
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <Flipper
              flipKey={Math.random()}
              staggerConfig={{
                // the "default" config will apply to staggered elements without explicit keys
                default: {
                  // default direction is forwards
                  reverse: false,
                  // default is .1, 0 < n < 1
                  speed: 0.5,
                },
                // this will apply to Flipped elements with the prop stagger='namedStagger'
                namedStagger: { speed: 0.2 },
              }}
            >
              <List
                className="black-scrollbar"
                style={{
                  scrollbarWidth: "none",
                }}
                overscanCount={5}
                itemCount={channelList.length}
                itemSize={56}
                width={width}
                height={height}
              >
                {_rowRenderer}
              </List>
            </Flipper>
          );
        }}
      </AutoSizer>
    );
  }, [_rowRenderer, channelList.length]);

  return (
    <div className="w-full flex-1 flex flex-col min-h-0">
      <div className="flex pl-[23px] w-full text-sm font-medium text-[#777E8F] leading-none border-b border-solid border-[#0A0C10] bg-[#20232C]">
        <div className="py-4 pr-5 w-[20%] shrink-0">Channel</div>
        <div className="py-4 pr-5 w-[10%] shrink-0">Subscribers</div>
        <div className="py-4 pr-5 w-[10%] shrink-0">Videos</div>
        <div className="py-4 pr-5 w-[10%] shrink-0">Views</div>
        <div className="py-4 pr-5 w-[10%] shrink-0">Status</div>
        <div className="py-4 pr-5 w-[15%] shrink-0">Added At</div>
        <div className="py-4 pr-5 w-[15%] shrink-0">Creation Date</div>

        <div
          className="py-4 w-[70px] text-ce
        nter ml-auto mr-1"
        >
          Actions
        </div>
      </div>
      <div className="flex-1 overflow-auto no-scrollbar">
        {channelList?.length === 0 ? (
          <p className="mt-8 text-center text-sm font-normal text-white/70">
            No Channel found
          </p>
        ) : (
          autoSizerMemo
        )}
      </div>
    </div>
  );
}

export default CompetitorsChannelTable;
