import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useMemo } from "react";

import { getAreaChartOption } from "./areaChartOption";

function AreaChart({ areaData }: { areaData: any[] }) {
  const filteredAreaData = useMemo(
    () =>
      areaData?.map((data: any) => ({
        x: data?.traceDate?.toDate()?.getTime(),
        y: data?.viewCount,
      })),
    [areaData]
  );
 
  return (
    <HighchartsReact
      containerProps={{ style: { height: "100%", width: "100%" } }}
      highcharts={Highcharts}
      options={getAreaChartOption(
        filteredAreaData?.length !== 0
          ? filteredAreaData
          : [
              {
                x: Date.now() - 10000,
                y: 0,
              },
              {
                x: Date.now() - 8000,
                y: 0,
              },
              {
                x: Date.now(),
                y: 0,
              },
              {
                x: Date.now() + 8000,
                y: 0,
              },
              {
                x: Date.now() + 10000,
                y: 0,
              },
            ],
        true
      )}
    />
  );
}

export default AreaChart;
