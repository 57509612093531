import { useState } from "react";

import SearchBar from "./searchBar";
import DropDown from "./dropDown";
import VideoDetailList from "./videoDetailList";
import { sortTimeCategory, sortCategory } from "utilities/constants";
import { ISelectOptionItems } from "interfaces/sortCategory.interface";
import { Checkbox } from "@mui/material";
import { grey } from '@mui/material/colors';
function TrendVideoPageContentTemplate({
  className = "",
  heading,
  headingIconUrl,
  isSavedVideosOnly,
}: {
  className?: string;
  heading: string;
  headingIconUrl?: string;
  isSavedVideosOnly?: boolean;
}) {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedSortCategory, setSelectedSortCategory] =
    useState<ISelectOptionItems>(sortCategory[0]);
  const [selectedSortTimeCategory, setSelectedSortTimeCategory] =
    useState<ISelectOptionItems>(
      sortTimeCategory[sortTimeCategory?.length - 1]
    );
  const [isSortChecked, setIsSortChecked] = useState<boolean>(false);
  return (
    <div
      className={`bg-[#14181D] h-full overflow-auto black-scrollbar  ${className}`}
    >
      <div className="min-w-[1144px] h-full flex flex-col">
        <div className="w-full px-6 py-[15px] flex items-center flex-wrap gap-2 justify-between border-b border-solid border-[#0A0C10]">
          <div className="flex items-center gap-1">
            {headingIconUrl && (
              <img
                src={headingIconUrl}
                alt="icon"
                className="w-5 h-5 shrink-0"
              />
            )}
            <h4 className="text-[18px] font-bold text-[#DDDDDD] leading-none">
              {heading}
            </h4>
          </div>

          <div className="flex items-center gap-3">
            <SearchBar value={searchText} onChange={setSearchText} />
            <div
              className="flex items-center  cursor-pointer gap-[5px]"
              onClick={() => setIsSortChecked(!isSortChecked)}
            >
              <Checkbox
                className="p-0 "
                checked={isSortChecked}
                sx={{
                  color: grey[800],
                  '&.Mui-checked': {
                    color:"#3254CE",
                  },
                }}
              />
              <label
                className="text-[15px] text-[#ffffff]/80 cursor-pointer font-[500]"
                htmlFor=""
              >
                Shorts Only
              </label>
            </div>

            <DropDown
              label="Time Period"
              selectedValueName={selectedSortTimeCategory.label}
              dropdownCategories={sortTimeCategory}
              setDropdownCategories={setSelectedSortTimeCategory}
            />
            <DropDown
              label="Sort by"
              selectedValueName={selectedSortCategory.label}
              dropdownCategories={sortCategory}
              setDropdownCategories={setSelectedSortCategory}
            />
          </div>
        </div>
        <VideoDetailList
          isSavedVideosOnly={isSavedVideosOnly}
          selectedSortCategory={selectedSortCategory}
          selectedSortTimeCategory={selectedSortTimeCategory}
          searchText={searchText}
          isSortChecked={isSortChecked}
        />
      </div>
    </div>
  );
}

export default TrendVideoPageContentTemplate;
