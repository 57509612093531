import { useState } from "react";

import { Button, CircularProgress } from "@mui/material";

import { addProject } from "hooks/data/useProjectData";

function AddProjectPopupCard({ handleClose }: { handleClose: () => void }) {
  const [projectName, setProjectName] = useState("");
  const [projectImageUrl, setProjectImageUrl] = useState("");

  const [isProjectAdditionInProgress, setIsProjectAdditionInProgress] =
    useState(false);

  const isAddButtonDisabled =
    !projectName || !projectImageUrl || isProjectAdditionInProgress;

  return (
    <div
      className="bg-[#14181F] w-full max-w-[393px] border border-solid border-[#20232C] rounded"
      style={{
        boxShadow: "0px 4px 4px 0px #00000014",
      }}
    >
      <h5 className="py-5 px-6 border-b rounded-t-[inherit] border-solid border-[#0A0C10] text-[18px] font-bold leading-none text-[#DDD]">
        Add New Project
      </h5>
      <div className="p-6 border-t border-solid border-[#20232C]">
        <div>
          <h6 className="text-[#DDDDDD] text-sm font-semibold leading-none">
            Project Name
          </h6>
          <div className="mt-3">
            <input
              type="text"
              className="bg-[#20232D] outline-0 rounded-[6px] border border-solid border-[#20232C] py-2 px-[10px] text-[#AFB4B9] text-sm font-medium w-full placeholder:text-[#AFB4B9]/50"
              placeholder="Enter Project Name"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-5">
          <h6 className="text-[#DDDDDD] text-sm font-semibold leading-none">
            Image Url
          </h6>
          <div className="mt-3">
            <input
              type="text"
              className="bg-[#20232D] outline-0 rounded-[6px] border border-solid border-[#20232C] py-2 px-[10px] text-[#AFB4B9] text-sm font-medium w-full placeholder:text-[#AFB4B9]/50"
              placeholder="Paste Project Imge Url"
              value={projectImageUrl}
              onChange={(e) => setProjectImageUrl(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-2 bg-[#20232D] border-t-[2px] border-solid border-[#0A0C10] rounded-b-[inherit] py-4 px-6">
        <Button
          onClick={handleClose}
          variant="contained"
          className="rounded bg-[#333848] hover:bg-[#333848]/80 capitalize px-4 py-[9px] text-sm font-semibold leading-none text-white"
          style={{
            boxShadow: "0px 1px 4px 0px #0000001F",
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isAddButtonDisabled}
          variant="contained"
          className={`rounded bg-[#3254CE] hover:bg-[#3254CE]/80 capitalize px-[14px] py-[9px] text-sm font-semibold leading-none text-white ${
            isAddButtonDisabled ? "grayscale" : ""
          }`}
          style={{
            boxShadow: "0px 1px 4px 0px #0000001F",
          }}
          onClick={() => {
            addProject(
              {
                admin: "uid",
                channels: [],
                createdAt: new Date(),
                image: projectImageUrl,
                lastUpdatedAt: new Date(),
                members: ["uid"],
                name: projectName,
              },
              {
                onStart: () => {
                  setIsProjectAdditionInProgress(true);
                },
                onSuccess: () => {
                  handleClose();
                  setIsProjectAdditionInProgress(false);
                },
              }
            );
          }}
        >
          Done
          {isProjectAdditionInProgress && (
            <CircularProgress className="text-white ml-2" size={14} />
          )}
        </Button>
      </div>
    </div>
  );
}

export default AddProjectPopupCard;
