import { useState } from "react";

import { Box, Tabs, Tab } from "@mui/material";

import SearchBar from "components/common/trendVideoPageContentTemplate/searchBar";
import DropDown from "components/common/trendVideoPageContentTemplate/dropDown";
import BoostVideoTable from "./boostVideoTable";
import { sortCategory, sortTimeCategory } from "utilities/constants";
import { ISelectOptionItems } from "interfaces/sortCategory.interface";

function BoostPageContent() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  useState<ISelectOptionItems>(sortCategory[0]);
  const [selectedSortTimeCategory, setSelectedSortTimeCategory] =
    useState<ISelectOptionItems>(sortTimeCategory[0]);
  const [selectedSortCategory, setSelectedSortCategory] =
    useState<ISelectOptionItems>(sortCategory[0]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  function getTabProps(index: number) {
    return {
      id: `boost-page-${index}`,
      "aria-controls": `boost-page-tabpanel-${index}`,
      className: `opacity-[1] p-4 font-semibold text-base leading-none tracking-[-0.02em]	 ${
        index === selectedTabIndex ? "text-[#ddd]" : "text-[#717788]"
      }`,
    };
  }

  return (
    <div className="bg-[#14181D]  h-full overflow-auto black-scrollbar border border-solid border-[#20232C] rounded-lg">
      <div className="min-w-[1144px] h-full flex flex-col">
        <div className="w-full px-6 flex items-end flex-wrap gap-2 justify-between border-b border-solid border-[#20232C]">
          <Box>
            <Tabs
              value={selectedTabIndex}
              onChange={handleChange}
              aria-label="tabs"
              textColor="inherit"
              TabIndicatorProps={{ sx: { bgcolor: "#3254CE" } }}
            >
              <Tab label="All Videos(23)" {...getTabProps(0)} />
              <Tab label="Not Boosted(20)" {...getTabProps(1)} />
              <Tab label="Boosted(3)" {...getTabProps(2)} />
            </Tabs>
          </Box>
          <div className="flex items-center gap-3 self-center py-3">
            <SearchBar value="ddd" onChange={() => undefined} />
            <DropDown
              label="Time Period"
              selectedValueName="This Week"
              dropdownCategories={sortTimeCategory}
              setDropdownCategories={setSelectedSortTimeCategory}
            />
            <DropDown
              label="Sort by"
              selectedValueName="Date"
              dropdownCategories={sortCategory}
              setDropdownCategories={setSelectedSortCategory}
            />
          </div>
        </div>

        <BoostVideoTable />
      </div>
    </div>
  );
}

export default BoostPageContent;
