import { useEffect } from "react";
import { toast } from "react-toastify";

import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { firebaseDb } from "utilities/firebase";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  channelsFetchLoadingStart,
  channelsFetchLoadingFail,
  channelsFetchLoadingSuccess,
  channelsReset,
} from "actionsAndReducers/channels/action";

import { IChannels, ISingleChannel } from "interfaces/channels.interface";

export function useLoadChannelData(selectedprojectId?: string) {
  const email = useAppSelector((state) => state.userDetails.email);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let unsubscribe: any;

    if (email && selectedprojectId) {
      try {
        dispatch(channelsFetchLoadingStart());

        const documentRef = collection(firebaseDb, "channels");

        const q = query(
          documentRef,
          where("data.projectId", "==", selectedprojectId)
        );

        unsubscribe = onSnapshot(q, (querySnapshot) => {
          const channels: IChannels = {};
          querySnapshot.forEach((doc) => {
            const data = doc?.data()?.data;

            const { channelId } = data || {};
            const docId = doc?.id;

            const key = channelId || docId;

            if (key) {
              channels[key] = { ...data, docId } as ISingleChannel;
            }
          });

          dispatch(channelsFetchLoadingSuccess(channels));
        });
      } catch (e) {
        dispatch(channelsFetchLoadingFail("Something went wrong."));
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, selectedprojectId]);

  useEffect(() => {
    return () => {
      dispatch(channelsReset());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export function deleteChannel(docId: string) {
  const docRef = doc(firebaseDb, "channels", docId);

  updateDoc(docRef, { "data.status": "deleting" })
    .then((docRef) => {})
    .catch((error) => {
      toast.error("Something went wrong!");
    });
}

export function addChannel(
  data: {
    channelURL: string;
    status: string;
    createdAt: Date;
    projectId: string;
  },
  callbacks: {
    onStart: () => void;
    onSuccess: () => void;
  }
) {
  const docRef = collection(firebaseDb, "channels");

  const { onSuccess, onStart } = callbacks || {};

  if (onStart) {
    onStart();
  }

  addDoc(docRef, { data })
    .then((docRef) => {
      if (onSuccess) {
        onSuccess();
      }
      // console.log(
      //   "Document has been deleted"
      // );
    })
    .catch((error) => {
      toast.error("Something went wrong!");
    });
}
