export enum DropDownTimeValueEnum {
  "12_HOURS" = "12_hours",
  "1_DAY" = "1_day",
  "1_WEEK" = "1_week",
  "1_MONTH" = "1_month",
  "1_YEAR" = "1_year",
  "ALL_TIME" = "all_time",
  "BEFORE_2_DAYS" = "before_2_days",
  "BEFORE_3_DAYS" = "before_3_days",
  "BEFORE_7_DAYS" = "before_7_days",
}
