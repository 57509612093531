import { useCallback } from "react";
import { useParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { Flipper, Flipped } from "react-flip-toolkit";
import { FixedSizeList as List } from "react-window";

import VideoDetailSingleItem from "./videoDetailSingleItem";

import { useLoadVideoData } from "hooks/data/useLoadVideoData";
import { useLoadChannelData } from "hooks/data/useLoadChannelData";

import { useAppSelector } from "hooks/redux";
import { ISelectOptionItems } from "interfaces/sortCategory.interface";
import { useMemo } from "react";
import { ISingleVideoItem } from "interfaces/videos.interface";

import { DropDownTimeValueEnum } from "enums/dropdown.enum";

import { isItemSelectedByTimeFilter } from "utilities/functions";

function VideoDetailList({
  isSavedVideosOnly,
  selectedSortCategory,
  selectedSortTimeCategory,
  searchText,
  isSortChecked
}: {
  isSavedVideosOnly?: boolean;
  selectedSortCategory: ISelectOptionItems;
  selectedSortTimeCategory: ISelectOptionItems;
  searchText: string;
  isSortChecked:boolean;
}) {
  const videoList = useAppSelector((state) => state.videos.data);
  const channelsData = useAppSelector((state) => state.channels.data);

  const filteredVideoList = useMemo(
    () =>
      videoList.filter((item: ISingleVideoItem) => {
        const { title, uploadDate, channelId } = item || {};

        const { name: channelName } =
          (channelsData && channelsData[channelId]) || {};

        const titleKeyword = (title || "")?.trim().toLowerCase();
        const searchTextKeyword = (searchText || "")?.trim().toLowerCase();
        const channelTextKeyword = (channelName || "")?.trim().toLowerCase();

        if (
          !searchTextKeyword ||
          titleKeyword?.includes(searchTextKeyword) ||
          channelTextKeyword?.includes(searchTextKeyword)
        ) {
          return isItemSelectedByTimeFilter(
            selectedSortTimeCategory.value as DropDownTimeValueEnum,
            uploadDate?.toDate()
          );
        }

        return false;
      }),

    [videoList, channelsData, searchText, selectedSortTimeCategory.value]
  );
  const { projectId } = useParams();

  useLoadVideoData(projectId, isSavedVideosOnly, selectedSortCategory,isSortChecked);
  useLoadChannelData(projectId);

  const _rowRenderer = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const listingItemData = filteredVideoList[index];

      const key = listingItemData.id;

      return (
        <div key={key} style={style}>
          <Flipped key={key} flipId={key}>
            <div>
              <VideoDetailSingleItem data={listingItemData} />
            </div>
          </Flipped>
        </div>
      );
    },
    [filteredVideoList]
  );

  const autoSizerMemo = useMemo(() => {
    return (
      <AutoSizer>
        {({ height, width }: { height: number; width: number }) => {
          return (
            <Flipper
              flipKey={Math.random()}
              staggerConfig={{
                // the "default" config will apply to staggered elements without explicit keys
                default: {
                  // default direction is forwards
                  reverse: false,
                  // default is .1, 0 < n < 1
                  speed: 0.5,
                },
                // this will apply to Flipped elements with the prop stagger='namedStagger'
                namedStagger: { speed: 0.2 },
              }}
            >
              <List
                className="black-scrollbar"
                style={{
                  scrollbarWidth: "none",
                }}
                overscanCount={5}
                itemCount={filteredVideoList.length}
                itemSize={78}
                width={width}
                height={height}
              >
                {_rowRenderer}
              </List>
            </Flipper>
          );
        }}
      </AutoSizer>
    );
  }, [_rowRenderer, filteredVideoList.length]);

  return (
    <div className="w-full flex-1 flex flex-col min-h-0">
      <div className="flex w-full gap-x-[20px] text-sm font-medium text-[#777E8F] leading-none border-b border-solid border-[#0A0C10] bg-[#20232C]">
        <div className="pl-4 py-4 w-[146px] shrink-0">Thumbnail</div>
        <div className="py-4 flex-[1.3] text-center">Channel</div>
        <div className="py-4 flex-[3]">Title</div>
        <div className="py-4 flex-1">Views</div>
        <div className="py-4 flex-1">Likes</div>
        {/* <div className="py-4 flex-1">Dislikes</div> */}
        {/* <div className="py-4 flex-1">L/D Ratio</div> */}
        <div className="py-4 flex-1">Comments</div>
        <div className="py-4 flex-[1] text-center">Weekly VPH</div>
        <div className="py-4 flex-[1] text-center">24h VPH</div>
        <div className="py-4 flex-1">Avg VPH</div>
        <div className="py-4 flex-[1.5]">Published</div>
        <div className="py-4 pr-1 flex-1 text-center">Save</div>
      </div>
      <div className="flex-1 overflow-auto no-scrollbar">
        {!filteredVideoList || filteredVideoList.length === 0 ? (
          <p className="mt-8 text-center text-sm font-normal text-white/70">
            No Video found
          </p>
        ) : (
          autoSizerMemo
        )}
      </div>
    </div>
  );
}

export default VideoDetailList;
