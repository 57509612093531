import { useState } from "react";
import { TextField, Box, Button, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";

import { useAppDispatch } from "hooks/redux";

import { signUpThunk, loginThunk } from "actionsAndReducers/userDetails/action";

function AuthPage() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isLoginInProgress, setIsLoginInProgress] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [isSignUpInProgress, setIsSignUpProgress] = useState(false);

  const dispatch = useAppDispatch();

  return (
    <div className="p-6 flex flex-col lg:flex-row gap-8 items-center justify-center min-h-[100vh]">
      <Box
        className="flex flex-col items-center max-w-full"
        component="form"
        sx={{
          "& > :not(style)": { width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <h2 className="text-center">Login</h2>
        <TextField
          label="Email"
          variant="standard"
          className="mt-3"
          value={loginEmail}
          onChange={(e) => setLoginEmail(e.target.value)}
        />
        <TextField
          label="password"
          variant="standard"
          className="mt-3"
          value={loginPassword}
          onChange={(e) => setLoginPassword(e.target.value)}
        />
        <Button
          variant="contained"
          className="mt-8"
          onClick={() => {
            if (!loginEmail) {
              toast.error("to login, Please enter Email.");
            } else if (!loginPassword) {
              toast.error("to login, Please enter Password.");
            } else {
              setIsLoginInProgress(true);

              dispatch(
                loginThunk({ email: loginEmail, password: loginPassword })
              ).then(() => {
                setIsLoginInProgress(false);
              });
            }
          }}
        >
          Login{" "}
          {isLoginInProgress && (
            <CircularProgress className="text-[red] ml-2 shrink-0" size={20} />
          )}
        </Button>
      </Box>
      <Box
        className="flex flex-col items-center border-t lg:border-t-0 lg:border-l border-dashed border-white/70 max-w-full pt-8 lg:pt-0"
        component="form"
        sx={{
          "& > :not(style)": { width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <h2 className="text-center">Sign Up</h2>
        <TextField
          label="Email"
          variant="standard"
          className="mt-3"
          value={signUpEmail}
          onChange={(e) => setSignUpEmail(e.target.value)}
        />
        <TextField
          label="password"
          variant="standard"
          className="mt-3"
          value={signUpPassword}
          onChange={(e) => setSignUpPassword(e.target.value)}
        />
        <Button
          variant="contained"
          className="mt-8"
          onClick={() => {
            if (!signUpEmail) {
              toast.error("to Sign up, Please enter Email.");
            } else if (!signUpPassword) {
              toast.error("to Sign up, Please enter Password.");
            } else {
              setIsSignUpProgress(true);
              dispatch(
                signUpThunk({ email: signUpEmail, password: signUpPassword })
              ).then(() => {
                setIsSignUpProgress(false);
              });
            }
          }}
        >
          Sign Up{" "}
          {isSignUpInProgress && (
            <CircularProgress className="text-[red] ml-2 shrink-0" size={20} />
          )}
        </Button>
      </Box>
    </div>
  );
}

export default AuthPage;
