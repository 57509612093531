import { ISelectOptionItems } from "./../interfaces/sortCategory.interface";

import { DropDownTimeValueEnum } from "enums/dropdown.enum";

export const sortCategory: ISelectOptionItems[] = [
  {
    label: "Avg VPH",
    value: "averageVPH",
  },
  {
    label: "Views",
    value: "views",
  },
];

export const sortTimeCategory: ISelectOptionItems[] = [
  {
    label: "12 Hours",
    value: DropDownTimeValueEnum["12_HOURS"],
  },
  {
    label: "1 day",
    value: DropDownTimeValueEnum["1_DAY"],
  },
  {
    label: "1 week",
    value: DropDownTimeValueEnum["1_WEEK"],
  },
  {
    label: "1 month",
    value: DropDownTimeValueEnum["1_MONTH"],
  },
  {
    label: "1 year",
    value: DropDownTimeValueEnum["1_YEAR"],
  },
  {
    label: "Before 2 days",
    value: DropDownTimeValueEnum.BEFORE_2_DAYS,
  },
  {
    label: "Before 3 days",
    value: DropDownTimeValueEnum.BEFORE_3_DAYS,
  },
  {
    label: "Before 7 days",
    value: DropDownTimeValueEnum.BEFORE_7_DAYS,
  },
  {
    label: "All Time",
    value: DropDownTimeValueEnum.ALL_TIME,
  },
];
