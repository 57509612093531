import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IProjects } from "interfaces/projects.interface";

export interface IProjectsState {
  data: IProjects;
  isLoading: boolean;
  error: string;
}

const initialState: IProjectsState = {
  data: {},
  isLoading: false,
  error: "",
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    projectsFetchLoadingStart: (state) => {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    },
    projectsFetchLoadingSuccess: (
      state,
      { payload }: PayloadAction<IProjects>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: "",
        data: payload,
      };
    },
    projectsFetchLoadingFail: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    },
    projectsReset: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export default projectsSlice.reducer;
