import TrendVideoPageContentTemplate from "components/common/trendVideoPageContentTemplate";

function SavedVideoPageContent({
  isSavedVideosOnly,
}: {
  isSavedVideosOnly?: boolean;
}) {
  return (
    <TrendVideoPageContentTemplate
      heading="Saved Videos"
      isSavedVideosOnly={isSavedVideosOnly}
    />
  );
}

export default SavedVideoPageContent;
