import AuthPage from "pages/authPage";

import { useAppSelector } from "hooks/redux";

interface WithAuthProps {}

export function withAuth<T extends WithAuthProps = WithAuthProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const ChildComponent = (props: Omit<T, keyof WithAuthProps>) => {
    const uid = useAppSelector((state) => state.userDetails.uid);

    if (!uid) {
      return <AuthPage />;
    }

    return <WrappedComponent {...(props as T)} />;
  };

  return ChildComponent;
}
