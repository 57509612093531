import { useState } from "react";

import SearchBar from "components/common/trendVideoPageContentTemplate/searchBar";
import CompetitorsChannelTable from "components/competitorsPage/competitorsChannelTable";
import AddChannelButton from "./addChannelButton";

function CompetitorsPageContent() {
  const [searchText, setSearchText] = useState<string>("");

  return (
    <div className="bg-[#14181D]  h-full overflow-auto black-scrollbar  ">
      <div className="min-w-[1144px] h-full flex flex-col">
        <div className="w-full px-6  py-3 flex items-center flex-wrap gap-2 justify-between border-b border-solid border-[#0A0C10]">
          <h4 className="text-[18px] font-bold text-[#DDDDDD] leading-none">
            Competitor Channels
          </h4>
          <div className="flex items-center gap-3 self-center">
            <SearchBar
              placeholder="Search Competitors"
              value={searchText}
              onChange={setSearchText}
            />
            <AddChannelButton />
          </div>
        </div>
        <CompetitorsChannelTable searchText={searchText} />
      </div>
    </div>
  );
}

export default CompetitorsPageContent;
