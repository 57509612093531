import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IUserDetailsState {
  email: string;
  uid: string;
}

const initialState: IUserDetailsState = {
  email: "",
  uid: "",
};

export const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<IUserDetailsState>) => {
      state.email = payload.email;
      state.uid = payload.uid;
    },
    signUp: (state, { payload }: PayloadAction<IUserDetailsState>) => {
      state.email = payload.email;
      state.uid = payload.uid;
    },
    logout: (state) => {
      state.email = "";
      state.uid = "";
    },
  },
});

export default userDetailsSlice.reducer;
