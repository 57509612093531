import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import TrendingVideosPage from "pages/trendingVideosPage";
import SavedVideoPage from "pages/savedVideoPage";
import BoostPage from "pages/boostPage";
import CompetitorsPage from "pages/competitorsPage";
import { useProjectData } from "hooks/data/useProjectData";

function MainRoutes() {
  useProjectData();
  return (
    <Router>
      <Routes>
        <Route
          path="/trending-videos/:projectId?"
          element={<TrendingVideosPage />}
        />
        <Route path="/saved-videos/:projectId?" element={<SavedVideoPage />} />
        <Route path="/boost/:projectId?" element={<BoostPage />} />
        <Route path="/competitors/:projectId?" element={<CompetitorsPage />} />

        <Route path="*" element={<Navigate to="/trending-videos" replace />} />
      </Routes>
    </Router>
  );
}

export default MainRoutes;
