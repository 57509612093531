export function getAreaChartOption(
  points: { x: number; y: number }[],
  isTooltipDisabled?: boolean
) {
  let lineColor = "rgb(1, 176, 124)";
  let stops = [
    [0, "rgba(1, 176, 124, 0.24)"],
    [0.5, "rgba(1, 176, 124,0.1)"],
    [1, "rgba(1, 176, 124, 0.0)"],
  ];

  // let lineColor = "rgb(240, 121, 121)";
  // let stops = [
  //   [0, "rgba(240, 121, 121, 0.24)"],
  //   [0.5, "rgba(240, 121, 121,0.1)"],
  //   [1, "rgba(240, 121, 121, 0.0)"],
  // ];

  return {
    chart: {
      backgroundColor: "transparent",
      type: "area",
      animation: false,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    title: {
      text: "",
    },
    time: {
      useUTC: false,
    },
    xAxis: {
      type: "datetime",
      lineWidth: 0,
      labels: {
        enabled: false,
      },
      gridLineColor: "transparent",
      lineColor: "transparent",
      crosshair: isTooltipDisabled
        ? false
        : {
            color: "rgba(255,255,255,0.5)",
            dashStyle: "shortdot",
            zIndex: 3,
            width: 1,
          },
    },
    tooltip: {
      enabled: !isTooltipDisabled,
      shared: true,
      backgroundColor: "#313741",
      borderColor: "transparent",
      borderRadius: 8,
      borderWidth: 0,
      padding: 4,
      style: {
        color: "#fff",
        fontSize: "8px",
      },
      useHTML: true,
    },
    yAxis: {
      title: {
        text: "",
        enabled: false,
      },
      gridLineColor: "transparent",
      labels: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops,
        },
        marker: {
          radius: points?.length > 1 ? 0 : 2,
          fillColor: lineColor,
        },
        lineWidth: 1,
        lineColor,
        states: {
          hover: {
            enabled: false,
          },
        },
        threshold: null,
      },
      series: {
        animation: false,
      },
    },
    series: [
      {
        type: "area",
        name: "",
        data: points,
      },
    ],
  };
}
