import Layout1 from "layouts/layout1";
import TrendVideoPageContent from "components/trendingVideosPage/trendVideoPageContent";

import { withAuth } from "hoc/withAuth";

function TrendingVideosPage() {
  return (
    <Layout1>
      <TrendVideoPageContent />
    </Layout1>
  );
}

export default withAuth(TrendingVideosPage);
