import TrendVideoPageContentTemplate from "components/common/trendVideoPageContentTemplate";

function TrendVideoPageContent() {
  return (
    <TrendVideoPageContentTemplate
      heading="Top videos from your competitors"
      headingIconUrl="/images/fire-icon.svg"
    />
  );
}

export default TrendVideoPageContent;
