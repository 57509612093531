import Layout1 from "layouts/layout1";
import SavedVideoPageContent from "components/savedVideoPage/savedVideoPageContent";

import { withAuth } from "hoc/withAuth";

function SavedVideoPage() {
  return (
    <Layout1>
      <SavedVideoPageContent isSavedVideosOnly />
    </Layout1>
  );
}

export default withAuth(SavedVideoPage);
