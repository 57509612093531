import Layout1 from "layouts/layout1";
import BoostPageContent from "components/boostPage/boostPageContent";

import { withAuth } from "hoc/withAuth";

function BoostPage() {
  return (
    <Layout1>
      <BoostPageContent />
    </Layout1>
  );
}

export default withAuth(BoostPage);
