import moment from "moment";

import { DropDownTimeValueEnum } from "enums/dropdown.enum";

export function numberFormater(number = 0, digits = 2) {
  if (number > 1e24 || typeof number !== "number") {
    return number;
  }
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
    { value: 1e21, symbol: "Z" },
    { value: 1e24, symbol: "Y" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (number >= si[i].value) {
      break;
    }
  }
  return `${(number / si[i].value).toFixed(digits).replace(rx, "$1")}${
    si[i].symbol
  }`;
}

export function isItemSelectedByTimeFilter(
  selectedFilterValue: DropDownTimeValueEnum,
  itemDate: Date
) {
  const oneDay = 24 * 60 * 60 * 1000;

  switch (selectedFilterValue) {
    case DropDownTimeValueEnum["12_HOURS"]: {
      return Date.now() - itemDate?.getTime() <= oneDay / 2;
    }
    case DropDownTimeValueEnum["1_DAY"]: {
      return Date.now() - itemDate?.getTime() <= oneDay;
    }
    case DropDownTimeValueEnum["1_MONTH"]: {
      const endDate = moment().subtract(1, "month");

      return endDate.valueOf() - itemDate?.getTime() <= 0;
    }
    case DropDownTimeValueEnum["1_WEEK"]: {
      const endDate = moment().subtract(1, "week");

      return endDate.valueOf() - itemDate?.getTime() <= 0;
    }
    case DropDownTimeValueEnum["1_YEAR"]: {
      const endDate = moment().subtract(1, "year");

      return endDate.valueOf() - itemDate?.getTime() <= 0;
    }
    case DropDownTimeValueEnum.BEFORE_2_DAYS: {
      return Date.now() - itemDate?.getTime() > 2 * oneDay;
    }
    case DropDownTimeValueEnum.BEFORE_3_DAYS: {
      return Date.now() - itemDate?.getTime() > 3 * oneDay;
    }
    case DropDownTimeValueEnum.BEFORE_7_DAYS: {
      return Date.now() - itemDate?.getTime() > 7 * oneDay;
    }
    default: {
      return true;
    }
  }
}

export function getEmptyValue(value: any) {
  if (value === undefined || value === null || value === "") {
    return "-";
  }
  return value;
}
