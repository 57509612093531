import { AiFillDelete } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import moment from "moment";

import CustomImage from "components/common/customImage";

import { ISingleChannel } from "interfaces/channels.interface";

import { numberFormater, getEmptyValue } from "utilities/functions";

import { deleteChannel } from "hooks/data/useLoadChannelData";

function CompetitorsChannelTableRow({
  data,
}: {
  data: ISingleChannel & { id: string };
}) {
  const {
    image,
    name,
    subscribers,
    isVerified,
    createdAt,
    status,
    docId,
    viewCount,
    videoCount,
    publishedAt,
    channelId,
  } = data || {};
  return (
    <div
      className={`flex pl-[23px] items-center text-sm font-normal text-[#AFB4B9] bg-[#14181D] border-y border-solid border-t-[#20232C] border-b-[#0A0C10] first:border-t-0`}
    >
      <div className="py-2 pr-5 w-[20%] shrink-0">
        <div>
          <a
            className="flex items-center text-inherit"
            href={`https://www.youtube.com/channel/${channelId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomImage
              src={image}
              alt="icon"
              className="w-[40px] h-[40px] rounded-full shrink-0 mr-[10px] object-cover object-center"
            />
            <span className="text-base font-semibold truncate">{name}</span>
            {isVerified && (
              <span className="w-[14px] ml-1 h-[14px] shrink-0 bg-[#3254CE] rounded-full inline-flex items-center justify-center">
                <BsCheckLg size={6} className="text-white" />
              </span>
            )}
          </a>
        </div>
      </div>
      <div className="py-2 pr-5 w-[10%] shrink-0">
        {numberFormater(getEmptyValue(subscribers))}
      </div>
      <div className="py-2 pr-5 w-[10%] shrink-0">
        {numberFormater(getEmptyValue(videoCount))}
      </div>
      <div className="py-2 pr-5 w-[10%] shrink-0">
        {numberFormater(getEmptyValue(viewCount))}
      </div>
      <div className="py-2 pr-5 w-[10%] shrink-0">{status}</div>
      <div className="py-2 pr-5 w-[15%] shrink-0">
        {getEmptyValue(moment(createdAt?.toDate()).fromNow())}
      </div>
      <div className="py-2 pr-5 w-[15%] shrink-0">
        {getEmptyValue(moment(publishedAt?.toDate()).fromNow())}
      </div>

      <div className="py-2 w-[70px] text-center ml-auto mr-1">
        <button type="button" onClick={() => deleteChannel(docId)}>
          <AiFillDelete className="text-[red]/70 hover:text-[red]" />
        </button>
      </div>
    </div>
  );
}

export default CompetitorsChannelTableRow;
