import { ReactComponent as TrendingVideoIcon } from "assetsImport/images/navIcons/trending-videos.svg";
import { ReactComponent as SavedVideoIcon } from "assetsImport/images/navIcons/saved-videos.svg";
import { ReactComponent as CompetitorsIcon } from "assetsImport/images/navIcons/competitors.svg";
import { ReactComponent as BoostIcon } from "assetsImport/images/navIcons/boost.svg";
import { ReactComponent as SettingIcon } from "assetsImport/images/navIcons/setting.svg";

export const navListData = [
  {
    title: "Trending videos",
    icon: TrendingVideoIcon,
    path: "/trending-videos",
    isCurrentParamAdd: true,
  },
  {
    title: "Saved Videos",
    icon: SavedVideoIcon,
    path: "/saved-videos",
    isCurrentParamAdd: true,
  },
  {
    title: "Competitors",
    icon: CompetitorsIcon,
    path: "/competitors",
    isCurrentParamAdd: true,
  },
  // {
  //   title: "Boost",
  //   icon: BoostIcon,
  //   path: "/boost",
  //   isCurrentParamAdd: true,
  // },
  // {
  //   title: "Manage",
  //   icon: SettingIcon,
  //   path: "/manage",
  //   isCurrentParamAdd: true,
  // },
];
