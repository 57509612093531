import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { IVideoList } from "interfaces/videos.interface";

export interface IVideosState {
  data: IVideoList;
  isLoading: boolean;
  error: string;
}

const initialState: IVideosState = {
  data: [],
  isLoading: false,
  error: "",
};

export const videosSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    videosFetchLoadingStart: (state) => {
      return {
        ...state,
        isLoading: true,
        error: "",
      };
    },
    videosFetchLoadingSuccess: (
      state,
      { payload }: PayloadAction<IVideoList>
    ) => {
      return {
        ...state,
        isLoading: false,
        error: "",
        data: payload,
      };
    },
    videosFetchLoadingFail: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    },
    videosUpdateSuccess: (state, { payload }: PayloadAction<IVideoList>) => {
      return {
        ...state,
        data: payload,
      };
    },
    videosReset: (state) => {
      return {
        ...state,
        ...initialState,
      };
    },
  },
});

export default videosSlice.reducer;
