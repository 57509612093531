import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { videosSlice } from "./reducer";

import { RootState } from "store";

import { updateVideoSavedStatusFirebase } from "hooks/data/useLoadVideoData";

export const {
  videosFetchLoadingStart,
  videosFetchLoadingSuccess,
  videosFetchLoadingFail,
  videosUpdateSuccess,
  videosReset,
} = videosSlice.actions;

export const updateSavedVideosThunk = createAsyncThunk(
  "userDetails/updateSavedVideosThunk",
  async (
    { videoId, isSaved }: { videoId: string; isSaved: boolean },
    { dispatch, getState }
  ) => {
    try {
      const videosList = (getState() as RootState)?.videos.data;

      dispatch(
        videosUpdateSuccess(
          videosList?.map((item) => ({
            ...item,
            isSaved: item.id === videoId ? isSaved : item.isSaved,
          }))
        )
      );

      await updateVideoSavedStatusFirebase(videoId, isSaved);
    } catch (e) {
      toast.error("Something went wrong!");

      const videosList = (getState() as RootState)?.videos.data;

      dispatch(
        videosUpdateSuccess(
          videosList?.map((item) => ({
            ...item,
            isSaved: item.id === videoId ? !isSaved : item.isSaved,
          }))
        )
      );
    }
  }
);
